export default {
  home: {
    checkLocales: '中国大陆',
    localTitle:'选择国家和地区',
    course: '历程',
    team: '团队',
    business: '业务',
    cooperation: '合作',
    contact: '联系',
    title: '专注环球资产配置',
    finnetMission: '我们的使命',
    missionContent: '金融造福大众',
    finnetVision: '我们的愿景',
    visionContent: '让每个人轻松投资适合自己的金融产品',
    title2014: '公司初创',
    introduce2014: '深圳Finnet投资咨询有限公司正式成立',
    title2016: '立足香港',
    introduce2016: '为对接海外优质金融资源，Finnet Company Limited 在香港成立',
    title2017: '拓展大陆',
    introduce2017: '开始拓展大陆业务',
    title2018: '深挖策略和服务',
    introduce2018: '明确选品策略，重视客户长期服务，整体业务开启在线化',
    title2019: '关键转型',
    introduce2019: '转型金融互联网公司， 在成都成立Finnet（成都）科技有限公司',
    title2020: '拥抱科技',
    introduce2020: '多项技术专利和科技成果相继上线，完成整体在线转型',
    title2022: '运营新纪元',
    introduce2022: '开启全新运营模式，成功开设分中心超十个，美国公司获MSB证书，成都公司获高新技术企业称号',
    title2023: '财富中心元年',
    introduce2023: '相继在深圳、西安、青岛、成都开设线下财富中心',
    title2024:'成立家族办公室',
    introduce2024:'专注富裕家庭财富管理，在成都开设Finnet炬量家族财富办公室',
    szadress: '2014｜深圳',
    szname: '深圳Finnet投资咨询有限公司',
    szintroduce: '链接香港的投资咨询平台',
    hkadress: '2016｜香港',
    hkname: 'Finnet Company Limited',
    hkintroduce: '对接国际优质金融资源和一流机构',
    usadress: '2019｜美国',
    usname: 'Finnet Inc.',
    usintroduce: '提供海外金融服务',
    cdadress: '2019｜成都',
    cdname: 'Finnet（成都）科技有限公司',
    cdintroduce: '中国大陆的研发和运营中心',
    caadress: '2024｜加拿大',
    caname: 'Finnet Financière Inc.',
    caintroduce: '海外金融市场开拓中心',
    teamsTitle: '精英团队',
    journeyTitle: "布局全球，",
    journeyTitle1:'为客户提供专业支持',
    markoffice: '创始人｜首席执行官',
    office: '联合创始人',
    markFlag1: '西南政法大学法学硕士',
    markFlag2: '拥有律师资格',
    markFlag3: '2010加入中伦律师事务所',
    markFlag4: '2011加入招商证券投行总部',
    markFlag5: '2014加入海外理财行业',
    cocoFlag1: '香港理工大学硕士',
    cocoFlag2: '资深理财顾问、AFP',
    cocoFlag3: '曾任香港康宏环球联席董事',
    cocoFlag4: '2014加入海外理财行业',
    linFlag1: '香港中文大学文学硕士',
    linFlag2: '资深理财顾问、AFP',
    linFlag3: '曾任香港康宏环球联席董事',
    linFlag4: '2014加入海外理财行业',
    huangFlag1: '巴黎高等商学院硕士',
    huangFlag2: '曾于五国学习工作',
    huangFlag3: '曾任世界500强人力资源管理',
    huangFlag4: '国际顶级高管猎头公司咨询顾问',
    staticTitle: '专注一件事，',
    staticTitle2: '环球资产配置',
    staticItemTitle1: '平台资产管理总量(HKD)',
    staticItemTitle2: '客户遍布城市',
    staticItemTitle3: '定投客户持续供款比例',
    staticItemTitle4: '定投客户正回报率',
    allocation:'资产配置服务',
    sp500Title:'海外基金定投',
    sp500Introduce:'进行人生的环球投资',
    evoTitle:'海外储蓄保障',
    evoIntroduce:'实现财富稳健增值',
    evosTitle:'海外旅行险',
    evosIntroduce:'让你出行游玩无虑',
    serviceLabel1:'在线认购',
    serviceValue1:'我们为客户提供线上签约服务，不论何时何地，通过微信几分钟即可完成签约操作，极大降低操作成本。',
    serviceLabel2:'在线账户管理',
    serviceValue2:'通过微信即可在线查看自己的海外资产，账户表现一览无遗。',
    serviceLabel3:'在线供款服务',
    serviceValue3:'客户可通过线上平台为海外投资账户进行供款、预缴操作，无须线下出境处理。',
    serviceLabel4:'投资者分析问卷',
    serviceValue4:'系统会根据问卷结果自动计算分析当前投资者的资产结构，结合投资者的逾期和实际情况，给出配置建议。',
    serviceLabel5:'投资月报',
    serviceValue5:'根据账户表现，每月自动生成可视化图表的账户报告，帮助投资者清晰直观地回顾账户。',
    serviceLabel6:'商学院',
    serviceValue6:'商学院是我们自主研发的内容平台，在这里可以学到丰富的投资理财知识。',
    serviceLabel7:'每日社群数据',
    serviceValue7:'每日市场的最新表现将会自动推送给到客户社群，方便客户浏览。',
    CooperatingOrg:'合作机构',
    bePartner:'合伙人',
    bePtnIntroduce:'实现财务自由，享受肆意人生',
    bePtnIntroduce1:'实现财务自由，',
    bePtnIntroduce2:'享肆意人生',
    bePtnErcode:'若对我们的事业有兴趣，欢迎与运营官联系微信',
    share:'环球资产配置 EST2014',
    szAdress:'深圳：深圳市福田区福华三路168号荣超国际商会中心1209B',
    hkAdress:'香港：UNIT 616 6/F KAM TEEM INDUSTRIAL BUILDING 135 CONNAUGHT RD WEST SAI WAN HK',
    cdAdress:'成都：四川省成都市武侯区桂溪街道天府五街菁蓉汇6A',
    usAdress:'美国：16192 COASTAL HIGHWAY, LEWES, DELAWARE 19958, COUNTY OF SUSSEX, U.S.A.',
    bqsy:'版权所有',
    nav:'导航栏',
    redb:'小红书',
    bili:'哔哩哔哩',
    video:'视频号',
    vx:'合伙人公众号',
    lpl:'亚洲',
    lec:'北美洲',
    turn:'跳转到'
  }
}
