<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
@font-face {
  font-family: din;
  /* src: url('./static/font/avant.ttf'), */
  src: url('/src/static/font/din.ttf'),
}

.family-din {
  font-family: 'din';
}

@font-face {
		font-family: avant;
		src: url('/src/static/font/avant.ttf'),
	}

	.family-avant {
		font-family: 'avant';
	}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

* {	
	margin : 0;
	padding : 0;
  /* background-color: chartreuse; */
}


body {
  margin: 0;
	padding : 0;
}
</style>
