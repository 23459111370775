export default {
  home:{
    checkLocales:'English',
    localTitle:'Select country and region',
    course:'journey',
    team:'teams',
    business:'service',
    cooperation:'collaborative',
    contact:'liaison',
    title:'Specialized in global asset allocation',
    finnetMission:'Our Mission',
    missionContent:'Finance for the benefit of the public',
    finnetVision:'Our Vision',
    visionContent:'Make it easy for everyone to invest in financial products that suit their needs',
    title2014:'company startup',
    introduce2014:'Shenzhen Finnet Investment Consulting Co.',
    title2016:'Based in Hong Kong',
    introduce2016:'Finnet Company Limited was established in Hong Kong to connect with overseas quality financial resources.',
    title2017:'Expanding to the continent',
    introduce2017:'Started to expand business in mainland China',
    title2018:'Digging deeper into strategies and services',
    introduce2018:'Clear product selection strategy, emphasis on long-term customer service, the overall business to start online',
    title2019:'Key transformations',
    introduce2019:'Transforms into a Financial Internet Company, Establishes Finnet (Chengdu) Technology Co.',
    title2020:'Embracing Technology',
    introduce2020:'A number of technical patents and scientific and technological achievements have been put online, completing the overall online transformation',
    title2022:'A New Era of Operation',
    introduce2022:'Opened a new mode of operation, successfully opened more than ten branch centers, the U.S. company was awarded the MSB certificate, Chengdu company was awarded the title of high-tech enterprises',
    title2023:'The Year of the Fortune Center',
    introduce2023:"Opened offline wealth centers in Shenzhen, Xi'an, Qingdao and Chengdu.",
    title2024:'Establishment of a family office',
    introduce2024:'Focuses on Wealth Management for Affluent Families, Opens Finnet Torch Volume Family Wealth Office in Chengdu',
    szadress:'2014｜Shenzhen ',
    szname:'Shenzhen Finnet Investment Consulting Co.',
    szintroduce:"Link to Hong Kong's Investment Advisory Platform",
    hkadress:'2016｜Hong Kong',
    hkname:'Finnet Company Limited',
    hkintroduce:'Matchmaking with international high-quality financial resources and first-class institutions',
    usadress:'2019｜United States',
    usname:'Finnet Inc.',
    usintroduce:'Provision of offshore financial services',
    cdadress:'2019｜Chengdu',
    cdname:'Finnet (Chengdu) Technology Co.',
    cdintroduce:'R&D and operation center in mainland China',
    caadress:'2024｜Canada',
    caname:'Finnet Financière Inc.',
    caintroduce:'Overseas Financial Market Development Center',
    teamsTitle:"Elite Team",
    journeyTitle:"Global presence and professional support for our customers",
    markoffice:'Founder｜CEO',
    office:'co-founder',
    markFlag1:'Master of Laws, Southwest University of Political Science and Law',
    markFlag2:'Admitted to the bar',
    markFlag3:'Joined Zhonglun Law Firm in 2010',
    markFlag4:'2011 Joined China Merchants Securities',
    markFlag5:'Join the offshore finance industry in 2014',
    cocoFlag1:'Master of Science, Hong Kong Polytechnic University',
    cocoFlag2:'Senior Financial Advisor, AFP',
    cocoFlag3:'Former Co-Director of Convoy Global Hong Kong',
    cocoFlag4:'Join the offshore finance industry in 2014',
    linFlag1:'Master of Arts, Chinese University of Hong Kong',
    linFlag2:'Senior Financial Advisor, AFP',
    linFlag3:'Former Co-Director of Convoy Global Hong Kong',
    linFlag4:'Join the offshore finance industry in 2014',
    huangFlag1:"Master's degree, Ecole Supérieure de Commerce, Paris",
    huangFlag2:'Studied and worked in five countries',
    huangFlag3:'Worked as a Fortune 500 Human Resources Manager',
    huangFlag4:'Consultant for top international executive search firm',
    staticTitle: "Focus on one thing.",
    staticTitle2: 'Global Asset Allocation',
    staticItemTitle1: 'Total platform assets under management (HKD)',
    staticItemTitle2: 'Customers across the city',
    staticItemTitle3: 'Percentage of ongoing contributions from fixed deposit clients',
    staticItemTitle4: 'Positive Returns for Fixed Clients',
    allocation:'Asset allocation services',
    sp500Title:'Overseas Fund Fixed Deposit',
    sp500Introduce:'Make a global investment of your life',
    evoTitle:'Overseas Savings Protection',
    evoIntroduce:'Achieve steady wealth growth',
    evosTitle:'Overseas Travel Insurance',
    evosIntroduce:'Let you travel and play without worry',
    serviceLabel1:'Online Subscription',
    serviceValue1:'We provide online signing service for our customers, no matter when and where, the signing operation can be completed in a few minutes through WeChat, which greatly reduces the operation cost.',
    serviceLabel2:'Online account management',
    serviceValue2:'You can view your overseas assets online via WeChat, with an overview of your account performance.',
    serviceLabel3:'Online Contribution Service',
    serviceValue3:"Customers can make contributions and pre-payments to their overseas investment accounts through the online platform, without the need to leave the country offline.",
    serviceLabel4:'Investor Analysis Questionnaire',
    serviceValue4:"The system will automatically calculate and analyze the current investor's asset structure based on the results of the questionnaire, and give allocation suggestions in combination with the investor's overdue and actual situation.",
    serviceLabel5:'Monthly Investment Report',
    serviceValue5:'Based on account performance, monthly account reports with visual charts are automatically generated to help investors review their accounts in a clear and intuitive manner.',
    serviceLabel6:'Finnet Business School',
    serviceValue6:"Business School is our self-developed content platform, where you can learn a wealth of investment and financial knowledge.",
    serviceLabel7:'Daily community data',
    serviceValue7:'Daily market performance updates will be automatically pushed to the client community for easy viewing.',
    CooperatingOrg:"Cooperating Organizations",
    bePartner:'Become a Partner',
    bePtnIntroduce:'Achieve financial freedom and enjoy a life of recklessness',
    bePtnIntroduce1:'Achieving Financial Freedom',
    bePtnIntroduce2:'Enjoy life with abandon',
    bePtnErcode:"If you are interested in Finnet's business, please feel free to contact the Operations Officer at WeChat",
    share:'Global Asset Allocation EST2014',
    szAdress:'Shenzhen: 1209B, Rongchao International Chamber of Commerce Center, No. 168 Fuhua 3rd Road, Futian District, Shenzhen, China',
    hkAdress:'HONG KONG: UNIT 616 6/F KAM TEEM INDUSTRIAL BUILDING 135 CONNAUGHT RD WEST SAI WAN HK',
    cdAdress:'Chengdu: 6A, Hibiscus Exchange, Tianfu 5th Street, Guixi Street, Wuhou District, Chengdu, Sichuan, China',
    usAdress:'United States: 16192 COASTAL HIGHWAY, LEWES, DELAWARE 19958, COUNTY OF SUSSEX, U.S.A.',
    bqsy:' all rights reserved',
    nav:'navigation bar',
    redb:'Little Red Book',
    bili:'Bilibili',
    video:'Channels',
    vx:'Official accounts',
    lpl:'Asian',
    lec:'North America',
    turn:'Jump to'
  }
}
