export default {
  home: {
    checkLocales: '中國大陸',
    localTitle:'選擇國家和地區',
    course: '歷程',
    team: '團隊',
    business: '業務',
    cooperation: '合作',
    contact: '聯系',
    title: '專註環球資產配置',
    finnetMission: '我們的使命',
    missionContent: '金融造福大眾',
    finnetVision: '我們的願景',
    visionContent: '讓每個人輕松投資適合自己的金融產品',
    title2014: '公司初創',
    introduce2014: '深圳Finnet投資咨詢有限公司正式成立',
    title2016: '立足香港',
    introduce2016: '為對接海外優質金融資源， Finnet Company Limited 在香港成立',
    title2017: '拓展大陸',
    introduce2017: '開始拓展大陸業務',
    title2018: '深挖策略和服務',
    introduce2018: '明確選品策略，重視客戶長期服務，整體業務開啟在線化',
    title2019: '關鍵轉型',
    introduce2019: '轉型金融互聯網公司，在成都成立Finnet（成都）科技有限公司',
    title2020: '擁抱科技',
    introduce2020: '多項技術專利和科技成果相繼上線，完成整體在線轉型',
    title2022: '運營新紀元',
    introduce2022: '開啟全新運營模式，成功開設分中心超十個，美國公司獲MSB證書，成都公司獲高新技術企業稱號',
    title2023: '財富中心元年',
    introduce2023: '相繼在深圳、西安、青島、成都開設線下財富中心',
    title2024:'成立家族辦公室',
    introduce2024:'專註富裕家庭財富管理，在成都開設Finnet炬量家族財富辦公室',
    szadress: '2014｜深圳',
    szname: '深圳Finnet投資咨詢有限公司',
    szintroduce: '鏈接香港的投資咨詢平臺',
    hkadress: '2016｜香港',
    hkname: 'Finnet Company Limited',
    hkintroduce: '對接國際優質金融資源和一流機構',
    usadress: '2019｜美國',
    usname: 'Finnet Inc.',
    usintroduce: '提供海外金融服務',
    cdadress: '2019｜成都',
    cdname: 'Finnet（成都）科技有限公司',
    cdintroduce: '中國大陸的研發和運營中心',
    caadress: '2024｜加拿大',
    caname: 'Finnet Financière Inc.',
    caintroduce: '海外金融市場開拓中心',
    teamsTitle: '精英團隊',
    journeyTitle: "布局全球，",
    journeyTitle1: "為客戶提供專業支持",
    markoffice: '創始人｜首席執行官',
    office: '聯合創始人',
    markFlag1: '西南政法大學法學碩士',
    markFlag2: '擁有律師資格',
    markFlag3: '2010加入中倫律師事務所',
    markFlag4: '2011加入招商證券投行總部',
    markFlag5: '2014加入海外理財行業',
    cocoFlag1: '香港理工大學碩士',
    cocoFlag2: '資深理財顧問、AFP',
    cocoFlag3: '曾任香港康宏環球聯席董事',
    cocoFlag4: '2014加入海外理財行業',
    linFlag1: '香港中文大學文學碩士',
    linFlag2: '資深理財顧問、AFP',
    linFlag3: '曾任香港康宏環球聯席董事',
    linFlag4: '2014加入海外理財行業',
    huangFlag1: '巴黎高等商學院碩士',
    huangFlag2: '曾於五國學習工作',
    huangFlag3: '曾任世界500强人力資源管理',
    huangFlag4: '国际顶级高管猎头公司咨询顾问',
    staticTitle: '專註一件事，',
    staticTitle2: '環球資產配置',
    staticItemTitle1: '平臺資產管理總量(HKD)',
    staticItemTitle2: '客戶遍佈都市',
    staticItemTitle3: '定投客戶持續供款比例',
    staticItemTitle4: '定投客戶正回報率',
    allocation:'資產配寘服務',
    sp500Title:'海外基金定投',
    sp500Introduce:'進行人生的環球投資',
    evoTitle:'海外儲蓄保障',
    evoIntroduce:'實現財富穩健增值',
    evosTitle:'海外旅行險',
    evosIntroduce:'讓你出行遊玩無慮',
    serviceLabel1:'線上認購',
    serviceValue1:'我們為客戶提供線上簽約服務，不論何時何地，通過微信幾分鐘即可完成簽約操作，極大降低操作成本。',
    serviceLabel2:'線上帳戶管理',
    serviceValue2:'通過微信即可線上查看自己的海外資產，帳戶表現一覽無遺。',
    serviceLabel3:'線上供款服務',
    serviceValue3:'客戶可通過線上平臺為海外投資賬戶進行供款、預繳操作，無須線下出境處理。',
    serviceLabel4:'投資者分析問卷',
    serviceValue4:'系統會根據問卷結果自動計算分析當前投資者的資產結構，結合投資者的逾期和實際情況，給出配寘建議。',
    serviceLabel5:'投資月報',
    serviceValue5:'根據帳戶表現，每月自動生成視覺化圖表的帳戶報告，幫助投資者清晰直觀地回顧帳戶。',
    serviceLabel6:'商學院',
    serviceValue6:'商學院是我們自主研發的內容平臺，在這裏可以學到豐富的投資理財知識。',
    serviceLabel7:'每日社群數據',
    serviceValue7:'每日市場的最新表現將會自動推送給到客戶社群，方便客戶瀏覽。',
    CooperatingOrg:'合作機构',
    bePartner:'合夥人',
    bePtnIntroduce:'實現財務自由，享受肆意人生',
    bePtnIntroduce1:'實現財務自由，',
    bePtnIntroduce2:'享肆意人生',
    bePtnErcode:'若對我們的事業有興趣，歡迎與運營官聯系微信',
    share:'環球資產配置 EST2014',
    szAdress:'深圳：深圳市福田區福華三路168號榮超國際商會中心1209B',
    hkAdress:'香港：UNIT 616 6/F KAM TEEM INDUSTRIAL BUILDING 135 CONNAUGHT RD WEST SAI WAN HK',
    cdAdress:'成都：四川省成都市武侯區桂溪街道天府五街菁蓉匯6A',
    usAdress:'美國：16192 COASTAL HIGHWAY, LEWES, DELAWARE 19958, COUNTY OF SUSSEX, U.S.A.',
    bqsy:'版權所有',
    nav:'導航欄',
    redb:'小紅書',
    bili:'嗶哩嗶哩',
    video:'視頻號',
    vx:'合夥人公眾號',
    lpl:'亞洲',
    lec:'北美洲',
    turn:'跳轉到'
  }
}
