
import { createRouter, createWebHistory } from 'vue-router'

const routerHistory = createWebHistory()

 const router = createRouter({
  history: routerHistory,
  routes: [{
    path: '/',
    name:'homePage',
    component: () => import('@/views/homePage/homePage'),
    // hidden: true
  },{
    path: '/detail',
    name:'detailIndex',
    component: () => import('@/views/details/detailIndex'),
    // hidden: true
  },]
})

export default router



