import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'default-passive-events';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import i18n from '@/locales/i18n.ts'; // i18n的配置文件路径，也就是 createI18n 的文件地址
import 'animate.css/animate.min.css'


const app = createApp(App)

app.use(router)
app.use(i18n)
app.use(ElementPlus)

app.mount('#app')
